
import { defineComponent, onMounted, ref, computed, onBeforeMount } from "vue";
import * as Yup from "yup";
import { useStore } from "vuex";
import { Actions, Mutations } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "ModalPopUp",
  components: {},
  props: {
    Valuem: Array,
    SelectedProduct: Array,
  },
  data() {
    return {
      checked: false,
    };
  },
  setup() {
    const List = ref();
    const store = useStore();
    const Combine = async (values, selected, valuem, index) => {
      console.log(values);
      if (!selected.trendyolProduct) {
        if (values.trendyolProduct) {
          var trendyolProduct = values.trendyolProduct;
          console.log(trendyolProduct);
          selected.trendyolProduct = trendyolProduct;
          valuem.splice(index, 1);
          console.log(selected);
        }
      }
      if (!selected.getirProduct) {
        if (values.getirProduct) {
          var getirProduct = values.getirProduct;
          console.log(getirProduct);
          selected.getirProduct = getirProduct;
          console.log(selected);
        }
      }
      if (!selected.newyemeksepetiproduct) {
        if (values.newyemeksepetiproduct) {
          var newyemeksepetiproduct = values.newyemeksepetiproduct;
          console.log(newyemeksepetiproduct);
          selected.newyemeksepetiproduct = newyemeksepetiproduct;
          console.log(selected);
        }
      }
    };
    const setdata = async (params) => {
      console.log(params);
      const temp: any = {
        user: store.getters.getUser,
        branchId: store.getters.getBranchId,
        data: params,
      };

      store.dispatch(Actions.Set_Combined_Products, temp);
    };
    /*
    
    try {
      const temp: any = {
        branchId: store.getters.getBranchId,
      };
      store.dispatch(Actions.Get_Products, temp).then((r) => {
        List.value = r;
      });
    } catch (e) {
      console.log(e);
    }
*/

    onBeforeMount(() => {
      console.log("onBeforeMount");
    });
    return {
      Combine,
      setdata,
    };
  },
});
